import React, { useState } from 'react';
import Provider from './service/provider';
import TokenContract from './service/token-contract';
import RouterContract from './service/router-contract';
import UniswapContract from './service/uniswap-contract';
import constants from './config/constants.json';
import { JsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import './App.css';
import {
  WETH,
  Trade,
  Fetcher,
  TokenAmount,
  TradeType,
  Route,
  Token,
  Percent
} from "@pancakeswap/sdk";
const providerJson = new JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545');

function toHex(Amount) {
  return `0x${Amount.raw.toString(16)}`;
}

function Swap() {
  const [ currency, setCurrency] = useState('eth');
  const [connect, setConnect] = useState('false');
  const [ walletAddress, setWalletAddress ] = useState('');
  const [ etherBalance, setEtherBalance ] = useState(0);
  const [ tokenBalance, setTokenBalance ] = useState(0);
  const [ tokenAmount, setTokenAmount ] = useState('');
  const [ etherAmount, setEtherAmount ] = useState('');
  const [ isConnected, setIsConnected ] = useState(false);


  const arrowClick = async (event) => {

    if (currency == "shiko") {
      setCurrency("eth");
    }

    else if (currency =="eth") {
      setCurrency("shiko");
    }
  }
  
  const connectClick = async (event) => {
    if (connect == 'false' && typeof window.ethereum !== 'undefined') {
      event.preventDefault();
      await window.ethereum.request({ method: 'wallet_addEthereumChain', params: [ constants.bnb ] });
      await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ "chainId": constants.chainId }] });
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = Provider.getInstance();
      setIsConnected(true);
      const contract = TokenContract.getInstance();
      let signer = provider.getSigner()
      const address = await signer.getAddress();
      setWalletAddress(address);
      let balance = await provider.getBalance(address);
      setEtherBalance(ethers.utils.formatEther(balance));
      let tokBalance = await contract.balanceOf(address);
      setTokenBalance(ethers.utils.formatUnits(tokBalance, await contract.decimals()));
      setConnect('true');
    }

    else if (typeof window.ethereum === 'undefined' ) {
      alert('Please install MetaMask');
    }
  }

  const etherChange = async (event) => {
    setEtherAmount(event.target.value);
    if(Number(event.target.value) > 0.000000000000000001 ){
      const wethAmount = ethers.utils.parseUnits(event.target.value,18);
      const uniswapContract = UniswapContract.getInstance();
      const provider = Provider.getInstance();
      const pair = await Fetcher.fetchPairData(uniswapContract, WETH[uniswapContract.chainId],provider);
      const route = new Route([pair], WETH[uniswapContract.chainId]); 
      const amountIn = wethAmount._hex;
      const trade = new Trade(
        route,
        new TokenAmount(WETH[uniswapContract.chainId], amountIn),
        TradeType.EXACT_INPUT
      );
      const slippageTolerance = new Percent("50", "10000"); // 50 bips, or 0.50%
      setTokenAmount(trade.minimumAmountOut(slippageTolerance).toExact());
    }

  }
  
  const tokenChange = async (event) => {
    console.log('event: ', event.target.value);
    setTokenAmount(event.target.value);
    if(Number(event.target.value) > 0.000000001 ){
      const wethAmount = ethers.utils.parseUnits(event.target.value,9);
      const uniswapContract = UniswapContract.getInstance();
      const provider = Provider.getInstance();
      const pair = await Fetcher.fetchPairData(uniswapContract, WETH[uniswapContract.chainId],provider);

      const route = new Route([pair], uniswapContract); 

      const amountIn = wethAmount._hex;
      const trade = new Trade(
        route,
        new TokenAmount(uniswapContract, amountIn),
        TradeType.EXACT_INPUT
      );
      const slippageTolerance = new Percent("50", "10000"); // 50 bips, or 0.50%
      setEtherAmount(trade.minimumAmountOut(slippageTolerance).toExact());
    }
  }

  const swap = async (event) => {
    event.preventDefault();
    const provider = Provider.getInstance();
    const address = walletAddress;
    let signer = provider.getSigner()

    const uniswapContract = UniswapContract.getInstance();

    const wethAmount = ethers.utils.parseUnits(etherAmount,18);

    const pair = await Fetcher.fetchPairData(uniswapContract, WETH[uniswapContract.chainId],provider);

    const route = new Route([pair], WETH[uniswapContract.chainId]); 

    const amountIn = wethAmount._hex;

    const trade = new Trade(
      route,
      new TokenAmount(WETH[uniswapContract.chainId], amountIn),
      TradeType.EXACT_INPUT
    );

    const slippageTolerance = new Percent("50", "10000"); // 50 bips, or 0.50%

    const amountOutMin = toHex(trade.minimumAmountOut(slippageTolerance)); // needs to be converted to e.g. hex
    const path = [WETH[uniswapContract.chainId].address, uniswapContract.address];
    const to = address; // should be a checksummed recipient address
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time
    const value = trade.inputAmount;

    let routerContract = RouterContract.getInstance();
    routerContract = routerContract.connect(signer);
    const gasPrice = await provider.getGasPrice();
    const gasEstimate = await routerContract.estimateGas.swapExactETHForTokens(
      amountOutMin,
      path,
      to,
      deadline,
      {
        value: toHex(value),
        gasPrice: gasPrice._hex
      }
    )
    console.log('gasEstimate: ', parseInt(gasEstimate._hex,16));
    console.log('gasEstimate: ', parseInt(gasPrice._hex,16));
    await routerContract.swapExactETHForTokens(
      amountOutMin,
      path,
      to,
      deadline,
      {
        gasLimit: gasEstimate._hex,  
        gasPrice: gasPrice._hex,
        // gasLimit: 800000,  
        value: toHex(value)
      }
    );

    setEtherAmount('');
    setTokenAmount('');

  }

  const swapEther = async (event) => {
    event.preventDefault();
    const provider = Provider.getInstance();
    const address = walletAddress;
    let signer = provider.getSigner()

    const uniswapContract = UniswapContract.getInstance();

    const wethAmount = ethers.utils.parseUnits(tokenAmount,9);
    const pair = await Fetcher.fetchPairData(uniswapContract, WETH[uniswapContract.chainId],provider);

    const route = new Route([pair], uniswapContract); 

    const amountIn = wethAmount._hex;
    const trade = new Trade(
      route,
      new TokenAmount(uniswapContract, amountIn),
      TradeType.EXACT_INPUT
    );
    const slippageTolerance = new Percent("50", "10000"); // 50 bips, or 0.50%

    const amountOutMin = toHex(trade.minimumAmountOut(slippageTolerance)); // needs to be converted to e.g. hex
    const path = [uniswapContract.address,WETH[uniswapContract.chainId].address];
    const to = address; // should be a checksummed recipient address
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 minutes from the current Unix time

    let routerContract = RouterContract.getInstance();
    routerContract = routerContract.connect(signer);
    const gasPrice = await provider.getGasPrice();
    const gasEstimate = await routerContract.estimateGas.swapExactTokensForETH(
      amountIn,
      amountOutMin,
      path,
      to,
      deadline,
      {
        gasPrice: gasPrice._hex
      }
    )
    console.log('gasEstimate: ', parseInt(gasEstimate._hex,16));
    console.log('gasEstimate: ', parseInt(gasPrice._hex,16));
    await routerContract.swapExactTokensForETH(
      amountIn,
      amountOutMin,
      path,
      to,
      deadline,
      {
        gasLimit: gasEstimate._hex,  
        gasPrice: gasPrice._hex,
        // gasLimit: 800000,  
      }
    );

    setEtherAmount('');
    setTokenAmount('');

  }

  let arrowBtn1, arrowBtn2, connect1;
  if (currency == "eth") {
    arrowBtn1 = <div className = "d-flex" style = {{justifyContent: "space-between"}}>
            <input type="number" class="form-control" placeholder = "0.0" value={etherAmount} onChange={etherChange}/> 
            <div className = "d-flex" style = {{flexFlow: 'row', justifyContent: 'flex-end', position: 'relative', top:12}}>
            <img src = "images/bnb.png" style = {{marginRight: 8, width: 24, height: 24}} />
            <div style = {{marginRight: 8, color: 'rgb(40, 13, 95)'}}>BNB</div>
          </div>
          </div>;
    
    arrowBtn2 = <div className = "d-flex" style = {{justifyContent: "space-between"}}>
            <input type="number" class="form-control" placeholder = "0.0" value={tokenAmount} onChange={tokenChange} />
            <div className = "d-flex" style = {{flexFlow: 'row', justifyContent: 'flex-end', position: 'relative', top:12}}>
            <img src = "images/currencys.png" style = {{marginRight: 8, width: 24, height: 24}} />
            <div style = {{marginRight: 8, color: 'rgb(40, 13, 95)'}}>SHIKO</div>
          </div>
          </div>; 
  }

  if (currency == "shiko")
   {
    arrowBtn1 = <div className = "d-flex" style = {{justifyContent: "space-between"}}>
            <input type="number" class="form-control" placeholder = "0.0" value={tokenAmount} onChange={tokenChange} /> 
            <div className = "d-flex" style = {{flexFlow: 'row', justifyContent: 'flex-end', position: 'relative', top:12}}>
            <img src = "images/currencys.png" style = {{marginRight: 8, width: 24, height: 24}} />
            <div style = {{marginRight: 8, color: 'rgb(40, 13, 95)'}}>SHIKO</div>
          </div>
          </div>;
    
    arrowBtn2 = <div className = "d-flex" style = {{justifyContent: "space-between"}}>
    <input type="number" class="form-control" placeholder = "0.0" value={etherAmount} onChange={etherChange} />
            <div className = "d-flex" style = {{flexFlow: 'row', justifyContent: 'flex-end', position: 'relative', top:12}}>
            <img src = "images/bnb.png" style = {{marginRight: 8, width: 24, height: 24}} />
            <div style = {{marginRight: 8, color: 'rgb(40, 13, 95)'}}>BNB</div>
          </div>
          </div>;
  }

  if (connect == 'false') {
    connect1 = <button className = "buttn" type="button" onClick={ connectClick }>Connect Wallet</button>;
  }

  if (connect == 'true' && currency == "eth") {
    connect1 = <button className = "buttn" type="button" onClick={ swap }>Swap</button>;
  }

  if (connect == 'true' && currency == "shiko") {
    connect1 = <button className = "buttn" type="button" onClick={ swapEther }>Swap</button>;
  }

  return ( 
    <section id = "swapp">
    <div className = "container">
      <div className = "d-flex flex-column">
        <h1> Swap </h1>
        <h2> Trade tokens in an instant </h2>
        <p className = "address">Wallet Address: { walletAddress }</p>
        <p>Ether Balance: { etherBalance }</p>
        <p>Token Balance: { tokenBalance }</p>
        <form onSubmit={ swap }>
          <div className = "forms d-flex flex-column">
          <label style = {{color:'rgb(40, 13, 95)'}}>From </label>
          {arrowBtn1}
          </div>
          
          <div className = "d-flex" style = {{justifyContent: 'center', marginBottom: 12, marginTop: 12}}>
            <button className = "buttn3" onClick = {arrowClick} type = "button" style = {{border: 'white', backgroundColor: 'white'}}>
              <svg  className = "arrows" viewBox="0 0 24 24" width="16px" color="text" xmlns="http://www.w3.org/2000/svg" >
                <path d="M11 5V16.17L6.11997 11.29C5.72997 10.9 5.08997 10.9 4.69997 11.29C4.30997 11.68 4.30997 12.31 4.69997 12.7L11.29 19.29C11.68 19.68 12.31 19.68 12.7 19.29L19.29 12.7C19.68 12.31 19.68 11.68 19.29 11.29C18.9 10.9 18.27 10.9 17.88 11.29L13 16.17V5C13 4.45 12.55 4 12 4C11.45 4 11 4.45 11 5Z"></path>
              </svg> 
            </button>
          </div>
          <div className = "forms d-flex flex-column">
          <label style = {{color:'rgb(40, 13, 95)'}}>To </label>
          {arrowBtn2}
          </div>
        </form>
        {/* {connect1} */}
        <button className = "buttn-disabled" type="button" disabled >Coming Soon</button>
        </div>
    </div>
    </section>
  );
}
 
export default Swap;